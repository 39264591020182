import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <App />
  </div>
);

//Styles

//Inline css style = {style = {property: "red"}}

//Internal css style = const customStyle = {property:""} access style ={customStyle}

//External Css using className

//Components = break down big javascript file into small chunks

//Import, Export and Modules =

// Default accessor we can change name in importing

// importing more than 1 by export {add, subtract, mutliply, divide}
//import * as Calculator from "./calculator";

//Props- Props uses to design more similar structures with different information

// Ex: Using Cards with different informations

// In Card Component
// function Card(props){
//   return <div>
// <h1>Name : {props.name}</h1>
// <p>Profession: {props.profession}</p>
// <img src={props.imgData} alt={props.alt}/>
// </div>
// }

//export default Card;

//Using In App.jsx
//import Card from "./Card";

// function App(){
//   return <div>
//     <Card name="Vinay" Profession="Web Developer"
//     imgData="img.jpg"
//     />
//   </div>
// }

//Mapping data to components
// const arr = [1, 2, 3, 4, 5];
// const arr1 = arr.map((a) => a * a);
// console.log(arr1);

//filter data to components
// const arr = [1, 2, 3, 4, 5];
// const arr1 = arr.filter((a) => a > 3);
// console.log(arr1);

//reduce data to components
// const arr = [1, 2, 3, 4, 5];
// const arr1 = arr.reduce((a, acc) => acc + a);
// console.log(arr1);

//Arrow Functions
//using arrows instead of return or function keyword

//conditional operator and nullish collashing

//const day = morning ? "coffee":"tea"

//const currentTime > 12 ?? <h1>why are you still working?</h1>

//Declarative programming and imperative programming

//Declarative programming - we use declarative programming in react.js by var isDone = true;
//imperative programming - we use imperative prgramming in dom selecting dom elements
//document.getElementById("id")

//React Hooks - We use hooks instead of class components
//in functional components we use hooks to set state to a changing part of program

//step-1 define hooks

// import {useState} from "react";

//step-2 defining useStates

// const [firstValue, finalValue] = useState(initialValue);

//step-3 function to handle a click

//function handleClick(){
// finalValue(firstValue + 1);
//}

//step-4 define where you want change

//Destructuring arrays and objects

//array destructuring
// const arr = ["vinay", "vivek", "mohit", "roshan"];
// const [a1, , a2] = arr;
// // first and third value will assigned to new a1 and a2
// console.log(a1, a2);

//Object destructuring
// const obj = [
//   {
//     key: 1,
//     fName: "Vinay",
//     lName: "Kumar",
//     contact: ["838000000", "vinaykumaryadav654"],
//   },
//   {
//     key: 2,
//     fName: "Roshan",
//     lName: "Kumar",
//   },
// ];

//Spread Operator it spreads an array into other

//by three dots

// const arr1 = [1, 2, 3, 4];
// const arr2 = [...arr1, 5, 6];
// console.log(arr2);

//Event handling - handling events with functions and operators is called event handling
